<template>
  <div class="emb-register-wrap section-gap">
    <div class="container py-0">
      <v-layout row wrap align-center justify-center>
        <v-flex sm12 md12 lg8 xl7>
          <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
            <v-flex sm6 md7 lg6 xl6 hidden-sm-and-down>
              <div class="form-img register-image"></div>
            </v-flex>
            <v-flex sm10 md5 lg6 xl6>
              <div
                class="emb-card sign-in-form form-margin d-block white pa-6"
                style="min-height: 400px; position: relative"
              >
                <v-form ref="form" v-model="valid">
                  <template v-if="!verified">
                    <h4>Enter Your Mobile Number</h4>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="verifyMobile"
                          v-bind="attrs"
                          v-on="on"
                          v-model="form.contact_number"
                          type="number"
                          placeholder="Mobile No*"
                          :rules="[
                            rules.required,
                            rules.mobile,
                            rules.maldivesNumber,
                          ]"
                          hint="Mobile Number should be registered in Maldives (Dhiraagu or Ooredoo)."
                        >
                        </v-text-field>
                      </template>
                      <span
                        >Should be a Valid Mobile number from Dhiraagu or
                        Ooredoo. DO NOT Enter +960 or 960.</span
                      >
                    </v-tooltip>
                    <v-btn
                      class="accent mx-0 mb-4"
                      v-if="!verifyMobile && !verified"
                      large
                      @click.stop.prevent="sendVerificationCode"
                    >
                      Send Verification Code
                    </v-btn>
                    <v-text-field
                      v-if="verifyMobile"
                      v-model="form.verification_code"
                      type="number"
                      placeholder="Verification Code*"
                      :rules="[rules.required]"
                      hint="Verification Code sent to your Mobile via SMS."
                    >
                    </v-text-field>
                    <v-btn
                      v-if="verifyMobile"
                      class="primary mx-0 mb-4"
                      large
                      @click.stop.prevent="confirmVerificationCode"
                    >
                      Confirm Verification Code
                    </v-btn>
                    <v-btn
                      v-if="verifyMobile"
                      class="accent mx-0 mb-4 pr"
                      large
                      @click.stop.prevent="reSendVerificationCode"
                    >
                      Re Send Verification Code
                    </v-btn>
                  </template>
                  <template v-if="verified">
                    <h4>Enter Your Details</h4>
                    <v-text-field
                      v-model="form.full_name"
                      type="text"
                      placeholder="Full Name*"
                      :rules="inputRules.basictextRules"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="form.display_name"
                      type="text"
                      placeholder="Display Name*"
                      :rules="inputRules.basictextRules"
                    >
                    </v-text-field>
                    <v-text-field
                      :disabled="true"
                      v-model="form.contact_number"
                      type="mobile"
                      placeholder="Mobile No*"
                      :rules="[
                        rules.required,
                        rules.mobile,
                        rules.maldivesNumber,
                      ]"
                      hint="Mobile Number should be registered in Maldives (Dhiraagu or Ooredoo)."
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="form.email_address"
                      type="email"
                      placeholder="Email*"
                      :rules="[rules.email]"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="form.password"
                      placeholder="Enter Password*"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.password]"
                      :type="show1 ? 'text' : 'password'"
                      label="Password"
                      hint="Min. 9 characters with at least one capital letter, a number and a special character."
                      counter
                      @click:append="show1 = !show1"
                    >
                    </v-text-field>
                    <v-text-field
                      class="mb-4"
                      v-model="form.password_confirmation"
                      placeholder="Retype Passowrd*"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        rules.required,
                        rules.password,
                        rules.password_confimation,
                      ]"
                      :type="show2 ? 'text' : 'password'"
                      label="Password Confirmation"
                      hint="Min. 9 characters with at least one capital letter, a number and a special character."
                      counter
                      @click:append="show2 = !show2"
                    >
                    </v-text-field>
                    <v-btn
                      class="accent mx-0 mb-4"
                      large
                      @click.stop.prevent="updateUserDetails"
                    >
                      Sign Up
                    </v-btn>
                  </template>
                  <p style="position: absolute; bottom: 0">
                    Already have account? then<router-link
                      to="/signin"
                      class="accent--text"
                    >
                      Sign In</router-link
                    >
                  </p>
                </v-form>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <v-dialog v-model="registered" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          Registration Suyccessful?
        </v-card-title>
        <v-card-text>
          Congratulations! <br />
          Registration Successful.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="proceedLogin">
            Proceed to Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
	
<script>
export default {
  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || "Password must match";
    },
  },
  data() {
    return {
      form: {
        full_name: "",
        display_name: "",
        contact_number: "",
        email_address: "",
        password: "",
        password_confirmation: "",
      },
      registered: false,
      verifyMobile: false,
      verified: false,
      show1: false,
      show2: false,
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      mobileRules: [
        (v) => !!v || "Mobile Number is required",
        (v) => v.length != 7 || "Mobile Number should be 7 digits",
      ],
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
      },
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) ||
          "Password must contain at least lowercase letter, one number, a special character and one uppercase letter",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 9 || "Min 9 characters",
        password: (value) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{9,})/;
          return (
            pattern.test(value) ||
            "Min. 9 characters with at least one capital letter, a number and a special character."
          );
        },
        password_confimation: (value) => {
          return (
            this.form.password === this.form.password_confirmation ||
            "Should be exactly the same as Password"
          );
        },
        mobile: (v) => {
          return v.length === 7 || "Should be 7 Numbers";
        },
        maldivesNumber: (v) => {
          return (
            (v > 7000000 && v < 8000000) ||
            (v > 9000000 && v < 9999999) ||
            "Should be a valid number from Dhiraagu or Ooredoo"
          );
        },
        email: (v) => {
          if (v.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
            return pattern.test(v) || "Should be a valid Email";
          } else {
            return true;
          }
        },
      },
    };
  },
  methods: {
    validateForm() {
      return this.$refs.form.validate();
    },
    sendVerificationCode() {
      if (!this.validateForm()) return;
      this.post("auth/send-verification-code", this.form)
        .then((response) => {
          if (response.data.status == true) {
            this.verifyMobile = true;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    reSendVerificationCode() {
      let f = {
        contact_number: this.form.contact_number,
        sales_channels_id: this.sales_channels_id,
      };
      this.post("auth/re-send-verification-code", f)
        .then((response) => {
          if (response.data.status == true) {
            this.verifyMobile = true;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    confirmVerificationCode() {
      if (!this.validateForm()) return;
      this.post("auth/verify-verification-code", this.form)
        .then((response) => {
          if (response.data.status == true) {
            this.verified = true;
            this.verifyMobile = false;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    updateUserDetails() {
      if (!this.validateForm()) return;
      this.post("auth/register-user", this.form)
        .then((response) => {
          if (response.data.status == true) {
            this.registered = true;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    proceedLogin() {
      this.registered = false;
      this.$router.push("/signin");
    },
  },
};
</script>